import styled from 'styled-components'

const Complete: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <Top>
        <Title>申請が完了いたしました</Title>
        <Title2>
          お客様情報のご入力・必要書類のアップロードありがとうございます。
          <br />
          ご購入は審査・抽選方式になりますので、お申し込みから2ヵ月以内を目処に結果をご案内します。
          <br />
          結果のご案内までもうしばらくお待ちください。
        </Title2>
      </Top>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`

const Top = styled.div`
  max-width: 760px;
  padding: 0 30px;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 1000px) {
    padding: 0 5%;
    width: 90%;
  }
`

const Title = styled.h1`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  height: auto;
  font-weight: 600;
  line-height: 1.5em;
  color: #000;
  margin: 0;
`

const Title2 = styled.h2`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  font-size: 1.1em;
  line-height: 1.1em;
  color: #333333;
`

export default Complete

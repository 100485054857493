import React, { useEffect, useCallback } from 'react'
import { useFormContext, Controller, ErrorOption } from 'react-hook-form'
import { useDropzone, FileRejection } from 'react-dropzone'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import styled from 'styled-components'

type DropzoneState = {
  isDragActive: boolean
  isDragAccept: boolean
  isDragReject: boolean
}

const getColor = (props: DropzoneState) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

type FileUploadsProps = {
  name: string
  required?: boolean
  min?: number
  max?: number
  length?: number
}

const FileUploads: React.VFC<FileUploadsProps> = ({
  name,
  required,
  min,
  max,
  length,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useFormContext()
  const uncastFiles = watch(name)
  const files: File[] = Array.isArray(uncastFiles) ? uncastFiles : []

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      let newFiles = [...(files || []), ...acceptedFiles]
      newFiles = newFiles.reduce((prev, file) => {
        if (
          prev.find((f: File) => {
            return (
              file.name == f.name &&
              file.size == f.size &&
              file.lastModified == f.lastModified
            )
          })
        ) {
          return prev
        } else {
          return [...prev, file]
        }
      }, [])
      setValue(name, newFiles)
      validateToSetErrors(newFiles.length)

      fileRejections.forEach((file: FileRejection) => {
        file.errors.forEach((err) => {
          if (
            err.code === 'file-too-large' ||
            err.code === 'file-invalid-type'
          ) {
            setError(name, { type: err.code } as ErrorOption, {
              shouldFocus: true,
            })
          }
        })
      })
    },
    [setValue, name, files],
  )

  const remove = (fileIndex: number) => {
    const newFiles = [...files]
    newFiles.splice(fileIndex, 1)
    setValue(name, newFiles)
    validateToSetErrors(newFiles.length)
  }

  // custom validation
  const validateToSetErrors = (filesLegth: number) => {
    clearErrors(name)
    if (required && filesLegth === 0) {
      setError(name, { type: 'required' } as ErrorOption, { shouldFocus: true })
    } else if (min && min > filesLegth) {
      setError(name, { type: 'min' } as ErrorOption, { shouldFocus: true })
    } else if (max && max < filesLegth) {
      setError(name, { type: 'max' } as ErrorOption, { shouldFocus: true })
    }
  }

  const thumbs = files.map((file, idx) => (
    <ThumbWrapper key={file.name}>
      <Thumb>
        <ThumbInner>
          {file.type === 'application/pdf' ? (
            <ThumbPdfDocument file={file}>
              <Page width={100} height={100} pageNumber={1} />
            </ThumbPdfDocument>
          ) : (
            <ThumbImg src={URL.createObjectURL(file)} />
          )}
        </ThumbInner>
      </Thumb>
      <DeleteButton onClick={() => remove(idx)}>削除</DeleteButton>
    </ThumbWrapper>
  ))

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(URL.createObjectURL(file)))
  }, [files])

  return (
    <Wrapper>
      <Controller
        render={() => {
          const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          } = useDropzone({
            accept: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
            onDrop: onDrop,
            // 10MB - 10KB。10KBはHeaderとかorderId分。
            maxSize: 10 * 1024 * 1024 - 10 * 1024,
            ...rest,
          })
          return (
            <Container
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
              <input {...getInputProps()} />
              <p>ファイルをアップロードする</p>
            </Container>
          )
        }}
        name={name}
        rules={{
          required,
          validate: {
            min: () => (min ? files.length >= min : true),
            max: () => (max ? files.length <= max : true),
            length: () => (length ? files.length === length : true),
          },
        }}
        control={control}
      />
      <ThumbsContainer>{thumbs}</ThumbsContainer>
      <ErrorMessageText>
        {errors[name]?.type === 'required' && 'アップロードしてください'}
        {errors[name]?.type === 'min' &&
          `ファイル枚数が足りません。${min}枚以上必要です。`}
        {errors[name]?.type === 'max' &&
          `ファイル枚数が多すぎます。${max}枚以下にしてください。`}
        {errors[name]?.type === 'length' &&
          `ファイルを${length}枚にしてください。`}
        {errors[name]?.type === 'file-too-large' &&
          '10MB以下のファイルのみアップロードが可能です'}
        {errors[name]?.type === 'file-invalid-type' &&
          'PNG、JPG、JPEG、PDFのファイル形式のみアップロードが可能です'}
      </ErrorMessageText>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 0 0 20px;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: DropzoneState) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:focus {
    border-color: #007aff;
  }
`

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px 0 8px;
`

const ThumbWrapper = styled.div`
  margin-bottom: 8px;
  :not(:first-child) {
    margin-left: 8px;
  }
`

const Thumb = styled.div`
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  width: 100px;
  height: 100px;
  padding: 4;
  box-sizing: border-box;
`

const ThumbInner = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 4px;
`

const ThumbImg = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
`

const ThumbPdfDocument = styled(Document)`
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
`

const DeleteButton = styled.button`
  display: block;
  width: 100px;
  color: #737373;
  background-color: #fff;
  border: none;
  font-size: 12px;
`

const ErrorMessageText = styled.p`
  line-height: 1.3em;
  margin: 0.2em 0 0.2em;
  font-size: 11px;
  letter-spacing: 0.024em;
  color: #e22120;
`

export default FileUploads

import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import logo from './logo.svg'
import './index.css'
import './normalize.css'
import Form from './Form'
import Complete from './Complete'
import NotFound from './404'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

const Header = styled.header`
  padding: 1.5em 30px;
`

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Header>
        <img width={71} height={20} src={logo} alt="logo" />
      </Header>
      <Switch>
        <Route exact path="/form">
          <Form />
        </Route>
        <Route exact path="/thankyou">
          <Complete />
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)

import React from 'react'
import styled from 'styled-components'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NotFound = () => (
  <Wrapper>
    <Title>404</Title>
    <TextContainer>
      <Text>This page could not be found</Text>
    </TextContainer>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 0;
  color: #000;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI',
    'Fira Sans', Avenir, 'Helvetica Neue', 'Lucida Grande', sans-serif;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  display: inline-block;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0;
  margin-right: 20px;
  padding: 10px 23px 10px 0;
  font-size: 24px;
  font-weight: 500;
  vertical-align: top;
`

const TextContainer = styled.div`
  display: inline-block;
  text-align: left;
  line-height: 49px;
  height: 49px;
  vertical-align: middle;
`

const Text = styled.h2`
  font-size: 14px;
  font-weight: normal;
  line-height: inherit;
  margin: 0;
  padding: 0;
`

export default NotFound

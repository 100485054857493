import { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  useForm,
  FormProvider,
  useFormContext,
  useController,
  ValidationRule,
} from 'react-hook-form'
import styled from 'styled-components'
import FileUploads from './FileUploads'
import axios from 'axios'

import applicationTypes from './data/application-types.json'
import beneficialOwnerCount from './data/beneficial-owner-count.json'
import firstContacts from './data/first-contacts.json'
import foreignPepss from './data/foreign-pepss.json'
import identifications from './data/identifications.json'
import industries from './data/industries.json'
import intentions from './data/intentions.json'
import interests from './data/interests.json'
import occupations from './data/occupations.json'
import partners from './data/partners.json'
import pets from './data/pets.json'
import places from './data/places.json'
import tourPlans from './data/tour-plans.json'
import tourRequests from './data/tour-requests.json'
import transactionPurposesEnterprise from './data/transaction-purposes-enterprise.json'
import transactionPurposes from './data/transaction-purposes.json'
import typesOfEnterprise from './data/types-of-enterprise.json'

const columnOther = 'その他'

type SelectOptionProps = {
  id: string
  name: string
  placeholder: string
  values: string[]
  required?: boolean
}

const SelectOptions: React.VFC<SelectOptionProps> = ({
  id,
  name,
  placeholder,
  values,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { field } = useController({
    control,
    name,
    defaultValue: '',
    rules: {
      required,
      shouldUnregister: true,
    },
  })
  const disabledLabel = '選択してください'

  return (
    <FieldWrapper>
      <FixedLabel htmlFor={id}>{placeholder}</FixedLabel>
      <Select
        id={id}
        placeholder={placeholder}
        className={errors[`${name}`] && 'message-error'}
        {...field}
      >
        <Option disabled value="">
          {disabledLabel}
        </Option>
        {values.map((label, idx) => (
          <Option key={`${id}-${idx}`} value={label}>
            {label}
          </Option>
        ))}
      </Select>
      <ErrorMessageText>
        {errors[`${name}`]?.type === 'required' &&
          `${placeholder}を選択してください`}
      </ErrorMessageText>
    </FieldWrapper>
  )
}

type RadioCheckboxProps = {
  id: string
  name: string
  placeholder: string
  required?: boolean
  values: string[]
}

type RadioCheckboxType = {
  type: 'radio' | 'checkbox'
}

const Radio: React.VFC<RadioCheckboxProps> = (props) => (
  <RadioCheckbox type="radio" {...props} />
)

const Checkbox: React.VFC<RadioCheckboxProps> = (props) => (
  <RadioCheckbox type="checkbox" {...props} />
)

const RadioCheckbox: React.VFC<RadioCheckboxProps & RadioCheckboxType> = ({
  id,
  name,
  type,
  placeholder,
  required,
  values,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FieldWrapper>
      <label htmlFor={id}>
        {placeholder}
        {type === 'checkbox' && ' (複数選択可)'}
      </label>
      <RadioCheckboxContainer>
        {values.map((value, idx) => (
          <RadioCheckboxWrapper key={`${id}${idx}`}>
            <RadioCheckboxInput
              {...register(name, { required, shouldUnregister: true })}
              id={`${id}${idx}`}
              type={type}
              value={value}
            />
            <label className="label" htmlFor={`${id}${idx}`}>
              {value}
            </label>
          </RadioCheckboxWrapper>
        ))}
      </RadioCheckboxContainer>
      <ErrorMessageText>
        {errors[`${name}`]?.type === 'required' &&
          `${placeholder}を選択してください`}
      </ErrorMessageText>
    </FieldWrapper>
  )
}

type TextFieldProps = {
  id: string
  name: string
  type: 'text' | 'number'
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
  placeholder?: string
  defaultValue?: string
  value?: string
  required?: boolean
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  pattern?: ValidationRule<RegExp>
}

const TextField: React.VFC<TextFieldProps> = ({
  id,
  name,
  type,
  inputMode,
  placeholder,
  defaultValue = '',
  value,
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()
  const watchValue = watch(name)
  const { field } = useController({
    control,
    name,
    defaultValue,
    rules: {
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      shouldUnregister: true,
    },
  })

  const textFieldClassName = (): string => {
    const classnames: string[] = []
    if (!!errors[`${name}`]) {
      classnames.push('message-error')
    }
    if (watchValue) {
      classnames.push('show')
    }
    classnames.push('half')
    return classnames.join(' ')
  }

  return (
    <FieldWrapper>
      <FloatingLabel className={watchValue && 'show'} htmlFor={id}>
        {placeholder}
      </FloatingLabel>
      <Input
        id={id}
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        className={textFieldClassName()}
        {...field}
        value={value}
      />
      <ErrorMessageText>
        {errors[`${name}`]?.type === 'required' &&
          `${placeholder}を入力してください`}
        {errors[`${name}`]?.type === 'maxLength' &&
          `${placeholder}を${maxLength}文字以下で入力してください`}
        {errors[`${name}`]?.type === 'min' &&
          `${placeholder}を${min}以上で入力してください`}
        {errors[`${name}`]?.type === 'max' &&
          `${placeholder}を${max}以下で入力してください`}
        {errors[`${name}`]?.type === 'pattern' &&
          `${placeholder}を正しく入力してください`}
      </ErrorMessageText>
    </FieldWrapper>
  )
}

const TextFieldBirthday: React.VFC<TextFieldProps> = ({
  id,
  name,
  type,
  inputMode,
  placeholder,
  defaultValue = '',
  required,
  min,
  max,
  minLength = 14,
  maxLength = 14,
}) => {
  const { setValue, watch } = useFormContext()
  const value = watch(name)
  if (typeof value === 'string') {
    // expect '1992 / 02 / 15'
    // 数字以外の文字を禁止
    const regexp = new RegExp(/[^0-9\s/]+/)
    if (regexp.test(value)) {
      setValue(name, value.replace(/[^0-9\s/]+/g, ''))
    }
    // 14文字以降は入力させない
    if (value.length > maxLength) {
      setValue(name, value.slice(0, maxLength))
    }
    // 8文字目が3以上の場合は、0を追加
    if (value.length === 8 && parseInt(value.slice(-1)) >= 3) {
      setValue(name, `${value.slice(0, -1)}0${value.slice(-1)}`)
    }
    // 13文字目が4以上の場合は、0を追加
    if (value.length === 13 && parseInt(value.slice(-1)) >= 4) {
      setValue(name, `${value.slice(0, -1)}0${value.slice(-1)}`)
    }
    // 4文字または9文字目の場合は ` / ` を追加
    if (value.length === 4 || value.length === 9) {
      setValue(name, `${value} / `)
    }
    // 末尾が ` /` の場合は3文字削除
    if (value.slice(-2) === ' /') {
      setValue(name, value.slice(0, -3))
    }
  }

  return (
    <TextField
      id={id}
      name={name}
      type={type}
      inputMode={inputMode}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      required={required}
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
      pattern={/(19|20)\d\d\s\/\s(0[1-9]|1[0-2])\s\/\s(0[1-9]|[12][0-9]|3[01])/}
    />
  )
}

const TextFieldZipcode: React.VFC<TextFieldProps> = ({
  id,
  name,
  type,
  inputMode,
  placeholder,
  defaultValue = '',
  required,
  min,
  max,
  minLength = 7,
  maxLength = 7,
}) => {
  const { setValue, watch } = useFormContext()
  const value = watch(name)
  if (typeof value === 'string') {
    // expect '1520003'
    // 数字以外の文字を禁止
    const regexp = new RegExp(/[^0-9]+/)
    if (regexp.test(value)) {
      setValue(name, value.replace(/[^0-9]+/g, ''))
    }
    // 7文字以降は入力させない
    if (value.length > maxLength) {
      setValue(name, value.slice(0, maxLength))
    }
  }

  return (
    <TextField
      id={id}
      name={name}
      type={type}
      inputMode={inputMode}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      required={required}
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
    />
  )
}

type TextFieldAdditionalProps = TextFieldProps & {
  placeholder: string
  shouldShow: () => boolean
}

// TextFieldのその他用のWrapper
// `shouldShow = true` のみ表示する
const TextFieldAdditional: React.VFC<TextFieldAdditionalProps> = ({
  id,
  name,
  type,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  placeholder,
  shouldShow,
}) => {
  const optionText = required ? '' : ' (任意)'
  const p = placeholder + optionText

  return (
    <>
      {shouldShow() && (
        <TextField
          id={id}
          name={name}
          type={type}
          placeholder={p}
          defaultValue={defaultValue}
          required={required}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
        />
      )}
    </>
  )
}

const App: React.FunctionComponent = () => {
  const history = useHistory()
  const search = useLocation().search
  // http://localhost:3000/?orderId=4033734377635
  const orderId = new URLSearchParams(search).get('orderId')

  if (orderId?.length !== 13) {
    return (
      <Wrapper>
        <Top>
          <Description>URLが不正です</Description>
        </Top>
      </Wrapper>
    )
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [formSendingError, setFormSendingError] = useState<string | null>(null)

  const methods = useForm({ shouldUnregister: true })
  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  // 申込種別が選択されたか否か
  const hasSelectedApplicationType = (): boolean => watch('applicationType')
  // 申込種別が個人か否か
  const isPersonalApplicationType = (): boolean =>
    watch('applicationType') === applicationTypes.values[0]
  // 申込種別が法人か否か
  const isEnterpriseApplicationType = (): boolean =>
    watch('applicationType') === applicationTypes.values[1]
  // 現地案内の希望か否か
  const isNeedTourRequest = (): boolean =>
    watch('tourRequest') === tourRequests.values[0]
  // 実質的支配者数の配列
  const beneficialOwners = (): number[] =>
    watch('beneficialOwnerCount')
      ? [...Array(Number(watch('beneficialOwnerCount')))].map((_, i) => i + 1)
      : []
  // 非上場か否か
  const isCorporationUnlisted = (): boolean =>
    watch('typeOfEnterprise') === typesOfEnterprise.values[1]
  // 本件のご担当者様の本人確認書類の添付の選択値
  const getIdentification = (): string => watch('identification')
  // 本件のご担当者様の本人確認書類の添付のデータ
  const matchIdentificationData = () => {
    const selected = getIdentification()
    const values = identifications.values.find(
      (v: { value: string }) => v.value === selected,
    )
    return values ? values : { value: '', length: 0 }
  }

  type OrderId = {
    orderId: string
  }

  type FileKeys = {
    // 収入が分かる書類の添付 (Google Drive Keys)
    identificationFileKeys: string[]
    // 直近の決算報告書の添付 (Google Drive Keys)
    finalTaxReturnFileKeys: string[]
    // 登記事項証明書の添付 (Google Drive Keys)
    financialStatementFileKeys: string[]
    // 本人確認書類の添付 (Google Drive Keys)
    companyRegistrationFileKeys: string[]
    // 株主名簿の添付 (Google Drive Keys)
    shareholderListFileKeys: string[]
  }

  type FormFields = OrderId &
    FileKeys & {
      // 申込種別
      // 個人 or 法人
      applicationType: string

      // お客様情報
      // 姓
      lastName: string
      // 名
      firstName: string
      // 生年月日
      birthday: string
      // 職業
      occupation: string
      // (職業-その他選択時)
      occupationOther: string
      // 会社名
      companyName: string
      // 年収
      annualIncome: number
      // 金融資産
      financialAsset: number
      // 取引目的
      transactionPurpose: string
      // 家族構成
      familyStructure: number
      // 家族構成
      familyStructureChildren: number
      // ペット飼育
      pet: boolean
      // 外国PEPs(重要な公的地位にある者)への該当
      foreignPeps: boolean

      // 法人情報
      // 会社名
      enterpriseCompanyName: string
      // 法人の種類
      typeOfEnterprise: string
      // (法人の種類-その他選択時)
      typeOfEnterpriseOther: string
      // 事業内容
      industry: string
      // (事業内容 - その他選択時)
      industryOther: string
      // 取引目的
      enterpriseTransactionPurpose: string
      // (取引目的 - その他選択時)
      enterpriseTransactionPurposeOther: string

      // 姓
      representativeLastName: string
      // 名
      representativeFirstName: string
      // セイ
      representativeLastNameKana: string
      // メイ
      representativeFirstNameKana: string
      // 生年月日
      representativeBirthday: string
      // 郵便番号
      representativeZipcode: string
      // 住所 (建物名等がある場合は必ず記載をしてくだい)
      representativeAddress: string

      // 実質的支配者情報1
      // 姓名
      beneficialOwnerName1: string
      // セイメイ
      beneficialOwnerNameKana1: string
      // 生年月日
      beneficialOwnerBirthday1: string
      // 郵便番号
      beneficialOwnerZipCode1: string
      // 住所 (建物名等がある場合は必ず記載をしてくだい)
      beneficialOwnerAddress1: string
      // 外国PEPs該当可否
      beneficialOwnerPeps1: boolean

      // 実質的支配者情報2
      // 姓名
      beneficialOwnerName2: string
      // セイメイ
      beneficialOwnerNameKana2: string
      // 生年月日
      beneficialOwnerBirthday2: string
      // 郵便番号
      beneficialOwnerZipCode2: string
      // 住所 (建物名等がある場合は必ず記載をしてくだい)
      beneficialOwnerAddress2: string
      // 外国PEPs該当可否
      beneficialOwnerPeps2: boolean

      // 実質的支配者情報3
      // 姓名
      beneficialOwnerName3: string
      // セイメイ
      beneficialOwnerNameKana3: string
      // 生年月日
      beneficialOwnerBirthday3: string
      // 郵便番号
      beneficialOwnerZipCode3: string
      // 住所 (建物名等がある場合は必ず記載をしてくだい)
      beneficialOwnerAddress3: string
      // 外国PEPs該当可否
      beneficialOwnerPeps3: boolean

      // 実質的支配者情報4
      // 姓名
      beneficialOwnerName4: string
      // セイメイ
      beneficialOwnerNameKana4: string
      // 生年月日
      beneficialOwnerBirthday4: string
      // 郵便番号
      beneficialOwnerZipCode4: string
      // 住所 (建物名等がある場合は必ず記載をしてくだい)
      beneficialOwnerAddress4: string
      // 外国PEPs該当可否
      beneficialOwnerPeps4: boolean

      // 本件のご担当者様の本人確認書類の添付
      identification: string

      // NOT A HOTELのご利用意向について
      // 年間の自己利用想定宿泊日数
      estimatedDaysOfUse: number
      // 平日・週末のご利用意向
      intention: string
      // どなたと利用したいか
      partners: string[]
      // (どなたと利用したいか - その他選択時)
      partnerOther: string
      // NOT A HOTELがあれば利用したい場所
      places: string[]
      // (NOT A HOTELがあれば利用したい場所 - その他選択時)
      placeOther: string
      // NOT A HOTELにご興味を持った理由
      interests: string[]
      // (NOT A HOTELにご興味を持った理由 - その他選択時)
      interestOther: string
      // NOT A HOTELを知ったきっかけ
      firstContact: string
      // (NOT A HOTEL知ったきっかけ - 知人からの紹介を選択時)
      firstContactAcquaintance: string
      // ご要望など
      comments: string

      // 現地案内の希望
      tourRequest: boolean
      // 第一希望
      tourPlans1: string
      // 第二希望（任意）
      tourPlans2: string
      // 第三希望（任意）
      tourPlans3: string
    }

  type FormFiles = {
    // 収入が分かる書類
    finalTaxReturnFiles: File[]
    // 直近の決算報告書
    financialStatementFiles: File[]
    // 登記事項証明書
    companyRegistrationFiles: File[]
    // 個人: 本人確認書類
    // 法人: 本件のご担当者様の本人確認書類
    identificationFiles: File[]
    // 株主名簿
    shareholderListFiles: File[]
  }

  type FormValues = FormFields & FormFiles

  const onSubmit = async (values: FormValues) => {
    values.orderId = orderId

    setLoading(true)
    setFormSendingError('')

    try {
      if (isPersonalApplicationType()) {
        const uploadFinancialStatementFiles = uploadFiles(
          '収入が分かる書類',
          values.finalTaxReturnFiles,
        )
        const uploadIdentificationFiles = uploadFiles(
          '本人確認書類',
          values.identificationFiles,
        )
        const [financialStatementFileKeys, identificationFileKeys] =
          await Promise.all([
            uploadFinancialStatementFiles,
            uploadIdentificationFiles,
          ])
        values.financialStatementFileKeys = financialStatementFileKeys
        values.identificationFileKeys = identificationFileKeys
      } else {
        const uploadFinancialStatementFiles = uploadFiles(
          '直近の決算報告書',
          values.financialStatementFiles,
        )
        const uploadCompanyRegistrationFiles = uploadFiles(
          '登記事項証明書',
          values.companyRegistrationFiles,
        )
        const uploadIdentificationFiles = uploadFiles(
          '本件のご担当者様の本人確認書類',
          values.identificationFiles,
        )

        const [
          financialStatementFileKeys,
          companyRegistrationFileKeys,
          identificationFileKeys,
        ] = await Promise.all([
          uploadFinancialStatementFiles,
          uploadCompanyRegistrationFiles,
          uploadIdentificationFiles,
        ])
        if (values.shareholderListFiles) {
          // May be undefined depending on the choice.
          const shareholderListFileKeys = await uploadFiles(
            '株主名簿',
            values.shareholderListFiles,
          )
          values.shareholderListFileKeys = shareholderListFileKeys
        }
        values.financialStatementFileKeys = financialStatementFileKeys
        values.companyRegistrationFileKeys = companyRegistrationFileKeys
        values.identificationFileKeys = identificationFileKeys
      }

      // Remove .*Files$ fields
      const {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        identificationFiles,
        finalTaxReturnFiles,
        financialStatementFiles,
        companyRegistrationFiles,
        shareholderListFiles,
        /* eslint-enable @typescript-eslint/no-unused-vars */
        ...params
      } = values
      const res = await appendData(params)
      console.log(res)
      history.push('/thankyou')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      } else {
        console.log(error)
      }
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.error === 'file size is zero'
      ) {
        setFormSendingError(
          'エラーが発生しました。お手数ですがファイルの形式やサイズをご確認のうえ、再度お試しください。なおエラーが解消しない場合はお手数ですが support@notahotel.com までご連絡ください。',
        )
      } else {
        setFormSendingError(
          '送信中にエラーが発生しました。再試行してください。',
        )
      }
    }

    setLoading(false)
  }

  const apiUrlBase = 'https://asia-northeast1-nah-sales.cloudfunctions.net'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appendData = async (params: FormFields): Promise<any> => {
    const apiPathAppendData = '/appendData'
    const url = apiUrlBase + apiPathAppendData
    return axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadFile = (key: string, file: File): Promise<any> => {
    const apiPathUpload = '/uploadFile'
    const url = apiUrlBase + apiPathUpload
    const params = new FormData()
    params.append(key, file)
    params.append('orderId', orderId)
    return axios.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: file,
      onUploadProgress: (progressEvent) => console.log(progressEvent),
    })
  }

  // `uploadFiles` is wrapper to process uploadFile in parallel
  // and then its returns Google Drive keys
  const uploadFiles = async (key: string, files: File[]): Promise<string[]> => {
    const uploads = files.map((f) => uploadFile(key, f))
    const ress = await Promise.all(uploads)
    return ress.map((res) => res.data[key] as string)
  }

  return (
    <Wrapper>
      <Top>
        <Title>NOT A HOTEL お客様追加情報フォーム</Title>
      </Top>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Section>
            <Title2>申込種別</Title2>
            <Radio
              id="applicationType"
              name="applicationType"
              required
              placeholder={applicationTypes.title}
              values={applicationTypes.values}
            />
          </Section>
          {hasSelectedApplicationType() && (
            <>
              {/* start personal */}
              {isPersonalApplicationType() && (
                <Section>
                  <Title2>お客様情報</Title2>
                  <TextField
                    id="lastName"
                    name="lastName"
                    type="text"
                    required
                    placeholder="姓"
                    maxLength={50}
                  />
                  <TextField
                    id="firstName"
                    name="firstName"
                    type="text"
                    required
                    placeholder="名"
                    maxLength={50}
                  />
                  <TextFieldBirthday
                    id="birthday"
                    name="birthday"
                    type="text"
                    inputMode="numeric"
                    required
                    placeholder="生年月日 (例: 1990 / 01 / 23)"
                  />
                  <SelectOptions
                    id="occupation"
                    name="occupation"
                    required
                    placeholder={occupations.title}
                    values={occupations.values}
                  />
                  <TextFieldAdditional
                    id="occupationOther"
                    name="occupationOther"
                    type="text"
                    required
                    placeholder={occupations.otherTextFieldPlaceholder}
                    shouldShow={() => {
                      const value = watch('occupation') as string
                      return value === columnOther
                    }}
                  />
                  <TextField
                    id="companyName"
                    name="companyName"
                    required
                    type="text"
                    placeholder="勤務先"
                    maxLength={100}
                  />
                  <TextField
                    id="annualIncome"
                    name="annualIncome"
                    type="number"
                    inputMode="numeric"
                    required
                    placeholder="年収 (万円)"
                    min={0}
                  />
                  <TextField
                    id="financialAsset"
                    name="financialAsset"
                    type="number"
                    inputMode="numeric"
                    required
                    placeholder="金融資産 (万円)"
                    min={0}
                  />
                  <SelectOptions
                    id="transactionPurpose"
                    name="transactionPurpose"
                    required
                    placeholder={transactionPurposes.title}
                    values={transactionPurposes.values}
                  />
                  <TextField
                    id="familyStructure"
                    name="familyStructure"
                    type="number"
                    inputMode="numeric"
                    placeholder="家族構成: 12歳以上 (人)"
                    min={0}
                    max={100}
                  />
                  <TextField
                    id="familyStructureChildren"
                    name="familyStructureChildren"
                    type="number"
                    inputMode="numeric"
                    placeholder="家族構成: 12歳以下 (人)"
                    min={0}
                    max={100}
                  />
                  <Radio
                    id="pet"
                    name="pet"
                    placeholder={pets.title}
                    values={pets.values}
                  />
                  <Radio
                    id="foreignPeps"
                    name="foreignPeps"
                    required
                    placeholder={foreignPepss.title}
                    values={foreignPepss.values}
                  />
                </Section>
              )}
              {/* end personal */}
              {/* start enterprise */}
              {isEnterpriseApplicationType() && (
                <>
                  <Section>
                    <Title2>法人情報</Title2>
                    <TextField
                      id="enterpriseCompanyName"
                      name="enterpriseCompanyName"
                      required
                      type="text"
                      placeholder="会社名"
                      maxLength={100}
                    />
                    <SelectOptions
                      id="typeOfEnterprise"
                      name="typeOfEnterprise"
                      required
                      placeholder={typesOfEnterprise.title}
                      values={typesOfEnterprise.values}
                    />
                    <TextFieldAdditional
                      id="typeOfEnterpriseOther"
                      name="typeOfEnterpriseOther"
                      type="text"
                      required
                      placeholder={typesOfEnterprise.otherTextFieldPlaceholder}
                      shouldShow={() => {
                        const value = watch('typeOfEnterprise') as string
                        return value === columnOther
                      }}
                    />
                    <SelectOptions
                      id="industry"
                      name="industry"
                      required
                      placeholder={industries.title}
                      values={industries.values}
                    />
                    <TextFieldAdditional
                      id="industryOther"
                      name="industryOther"
                      type="text"
                      required
                      placeholder={industries.otherTextFieldPlaceholder}
                      shouldShow={() => {
                        const value = watch('industry') as string
                        return value === columnOther
                      }}
                    />
                    <SelectOptions
                      id="enterpriseTransactionPurpose"
                      name="enterpriseTransactionPurpose"
                      required
                      placeholder={transactionPurposesEnterprise.title}
                      values={transactionPurposesEnterprise.values}
                    />
                    <TextFieldAdditional
                      id="enterpriseTransactionPurposeOther"
                      name="enterpriseTransactionPurposeOther"
                      type="text"
                      required
                      placeholder={
                        transactionPurposesEnterprise.otherTextFieldPlaceholder
                      }
                      shouldShow={() => {
                        const value = watch(
                          'enterpriseTransactionPurpose',
                        ) as string
                        return value === columnOther
                      }}
                    />
                  </Section>
                  <Section>
                    <Title2>本件のご担当者様情報</Title2>
                    <HalfTextWrapper>
                      <TextField
                        id="representativeLastName"
                        name="representativeLastName"
                        type="text"
                        required
                        placeholder="姓"
                        maxLength={50}
                      />
                      <TextField
                        id="representativeFirstName"
                        name="representativeFirstName"
                        type="text"
                        required
                        placeholder="名"
                        maxLength={50}
                      />
                    </HalfTextWrapper>
                    <HalfTextWrapper>
                      <TextField
                        id="representativeLastNameKana"
                        name="representativeLastNameKana"
                        type="text"
                        required
                        placeholder="セイ"
                        maxLength={50}
                      />
                      <TextField
                        id="representativeFirstNameKana"
                        name="representativeFirstNameKana"
                        type="text"
                        required
                        placeholder="メイ"
                        maxLength={50}
                      />
                    </HalfTextWrapper>
                    <TextFieldBirthday
                      id="representativeBirthday"
                      name="representativeBirthday"
                      type="text"
                      inputMode="numeric"
                      required
                      placeholder="生年月日 (例: 1990 / 01 / 23)"
                    />
                    <TextFieldZipcode
                      id="representativeZipcode"
                      name="representativeZipcode"
                      type="text"
                      required
                      placeholder="郵便番号 (例: 1510051)"
                    />
                    <TextField
                      id="representativeAddress"
                      name="representativeAddress"
                      type="text"
                      required
                      placeholder="住所 (建物名等がある場合は必ず記載をしてくだい)"
                      maxLength={500}
                    />
                  </Section>
                  <Section>
                    <Title2>実質的支配者の確認</Title2>
                    <Description>
                      ※1実質的支配者の本人特定事項等は、お客様からの申告による確認とさせていただいております。
                      <br />
                      ※2実質的支配者が複数人いる場合は全員分記載をお願い致します。
                      <br />
                      ◆資本多数決の原則を採る法人(株式会社、投資法人、特定目的会社など)
                      <br />①
                      当該法人の議決権総数の25％超の議決権を直接又は間接に保有する自然人
                      <br />
                      ②（①がいない場合）出資、融資、取引その他の関係を通じて事業活動に支配的な影響力を有すると認められる自然人
                      <br />
                      ③（①・②がいない場合）法人を代表し、その業務を執行する自然人
                      <br />
                      ◆上記以外の法人（一般社団・財団法人、学校法人、宗教法人、医療法人、社会福祉法人、特定非営利活動法人、持分会社（
                      合名会社、合資会社、合同会社）など）
                      <br />① 法人の収益総額の25%超の配当を受ける自然人
                      <br />
                      ②（又は）出資、融資、取引その他の関係を通じて事業活動に支配的な影響力を有すると認められる自然人
                      <br />
                      ③（①・②がいない場合）法人を代表し、その業務を執行する自然人
                    </Description>
                    <SelectOptions
                      id="beneficialOwnerCount"
                      name="beneficialOwnerCount"
                      required
                      placeholder={beneficialOwnerCount.title}
                      values={beneficialOwnerCount.values}
                    />
                    {beneficialOwners().map((_, idx) => (
                      <div key={`beneficialOwners${idx}`}>
                        <Title3>実質的支配者情報 {idx + 1}</Title3>
                        <TextField
                          id={`beneficialOwnerName${idx + 1}`}
                          name={`beneficialOwnerName${idx + 1}`}
                          type="text"
                          required
                          placeholder="姓名"
                          maxLength={100}
                        />
                        <TextField
                          id={`beneficialOwnerNameKana${idx + 1}`}
                          name={`beneficialOwnerNameKana${idx + 1}`}
                          type="text"
                          required
                          placeholder="セイメイ"
                          maxLength={100}
                        />
                        <TextFieldBirthday
                          id={`beneficialOwnerBirthday${idx + 1}`}
                          name={`beneficialOwnerBirthday${idx + 1}`}
                          type="text"
                          inputMode="numeric"
                          required
                          placeholder="生年月日 (例: 1990 / 01 / 23)"
                        />
                        <TextFieldZipcode
                          id={`beneficialOwnerZipcode${idx + 1}`}
                          name={`beneficialOwnerZipcode${idx + 1}`}
                          type="text"
                          required
                          placeholder="郵便番号 (例: 1510051)"
                        />
                        <TextField
                          id={`beneficialOwnerAddress${idx + 1}`}
                          name={`beneficialOwnerAddress${idx + 1}`}
                          type="text"
                          required
                          placeholder="住所 (建物名等がある場合は必ず記載をしてくだい)"
                          maxLength={500}
                        />
                        <Radio
                          id={`beneficialOwnerPeps${idx + 1}`}
                          name={`beneficialOwnerPeps${idx + 1}`}
                          required
                          placeholder={foreignPepss.title}
                          values={foreignPepss.values}
                        />
                      </div>
                    ))}
                  </Section>
                </>
              )}
              {/* end enterprise */}
              <Section>
                <Title2>提出書類</Title2>
                <Description>
                  提出書類
                  <br />
                  ※ぼやけ、見切れ等がないかを事前にご確認のうえ、アップロードをしてください
                  <br />
                  ※スマートフォンで撮影した画像もご利用いただけます
                  <br />
                  ※PNG、JPG、JPEG、PDFのファイル形式のみアップロードが可能です
                  <br />
                  ※10MB以下のファイルのみアップロードが可能です
                </Description>
                {isPersonalApplicationType() && (
                  <>
                    <label>収入が分かる書類の添付</label>
                    <Description>
                      直近の収入が分かる下記のいずれか1種類を添付してください
                      <br />
                      ・確定申告書
                      <br />
                      ・源泉徴収票
                    </Description>
                    <FileUploads
                      key="finalTaxReturnFiles"
                      name="finalTaxReturnFiles"
                      min={1}
                      required
                    />
                  </>
                )}
                <label>
                  {isPersonalApplicationType()
                    ? '本人確認書類の添付'
                    : '本件のご担当者様の本人確認書類の添付'}
                </label>
                <Description>
                  現住所の記載がある下記のいずれか1種類を添付してください
                  <br />
                  ・運転免許証 (表裏) : 2枚
                  <br />
                  ・在留カード (表裏) : 2枚
                  <br />
                  ・旅券 (顔写真ページ) + 健康保険証 (表/裏) : 3枚
                </Description>
                <SelectOptions
                  id="identification"
                  name="identification"
                  required
                  placeholder={identifications.title}
                  values={identifications.values.map((v) => v.value)}
                />
                {getIdentification() && (
                  <FileUploads
                    key="identificationFiles"
                    name="identificationFiles"
                    length={matchIdentificationData().length}
                    required
                  />
                )}
                {isEnterpriseApplicationType() && (
                  <>
                    <label>直近の決算報告書の添付</label>
                    <FileUploads
                      key="financialStatementFiles"
                      name="financialStatementFiles"
                      min={1}
                      required
                    />
                    <label>登記事項証明書の添付</label>
                    <FileUploads
                      key="companyRegistrationFiles"
                      name="companyRegistrationFiles"
                      min={1}
                      required
                    />
                    {isCorporationUnlisted() && (
                      <>
                        <label>株主名簿の添付 (任意)</label>
                        <Description>
                          非上場会社の場合、株主名簿を添付してください
                        </Description>
                        <FileUploads
                          key="shareholderListFiles"
                          name="shareholderListFiles"
                        />
                      </>
                    )}
                  </>
                )}
              </Section>
              <Section>
                <Title2>NOT A HOTELのご利用意向について</Title2>
                <TextField
                  id="estimatedDaysOfUse"
                  name="estimatedDaysOfUse"
                  type="number"
                  inputMode="numeric"
                  placeholder="年間の自己利用想定宿泊日数 (日)"
                  required
                  min={0}
                  max={365}
                />
                <SelectOptions
                  id="intention"
                  name="intention"
                  required
                  placeholder={intentions.title}
                  values={intentions.values}
                />
                <Checkbox
                  id="partners"
                  name="partners"
                  required
                  placeholder={partners.title}
                  values={partners.values}
                />
                <TextFieldAdditional
                  id="partnerOther"
                  name="partnerOther"
                  type="text"
                  placeholder={partners.otherTextFieldPlaceholder}
                  shouldShow={() => {
                    const values = watch('partners') as string[]
                    return Array.isArray(values)
                      ? values.includes(columnOther)
                      : false
                  }}
                />
                <Checkbox
                  id="interests"
                  name="interests"
                  required
                  placeholder={interests.title}
                  values={interests.values}
                />
                <TextFieldAdditional
                  id="interestOther"
                  name="interestOther"
                  type="text"
                  placeholder={interests.otherTextFieldPlaceholder}
                  shouldShow={() => {
                    const values = watch('interests') as string[]
                    return Array.isArray(values)
                      ? values.includes(columnOther)
                      : false
                  }}
                />
                <Checkbox
                  id="places"
                  name="places"
                  required
                  placeholder={places.title}
                  values={places.values}
                />
                <TextFieldAdditional
                  id="placeOther"
                  name="placeOther"
                  type="text"
                  placeholder={places.otherTextFieldPlaceholder}
                  shouldShow={() => {
                    const values = watch('places') as string[]
                    return Array.isArray(values)
                      ? values.includes(columnOther)
                      : false
                  }}
                />
                <SelectOptions
                  id="firstContact"
                  name="firstContact"
                  required
                  placeholder={firstContacts.title}
                  values={firstContacts.values}
                />
                <Description>
                  ご購入に際してはNOT A
                  HOTELの開発者、建築家造園家、デザイナー、写真家など、NOT A
                  HOTEL
                  を共につくるクリエイターやホテリエからのご紹介者様を優先した審査を行います。ご紹介者がいらっしゃる場合にはお名前をご記載ください。
                </Description>
                <TextFieldAdditional
                  id="firstContactAcquaintance"
                  name="firstContactAcquaintance"
                  required
                  type="text"
                  placeholder={firstContacts.acquaintanceTextFieldPlaceholder}
                  shouldShow={() => {
                    const value = watch('firstContact') as string
                    return value === firstContacts.values[2] // '知人からの紹介'
                  }}
                />
                <TextField
                  id="comments"
                  name="comments"
                  type="text"
                  placeholder="ご要望等ございましたらご記載ください"
                  maxLength={1000}
                />
              </Section>
              <Section>
                <Title2>現地案内の希望</Title2>
                <Radio
                  id="tourRequest"
                  name="tourRequest"
                  required
                  placeholder={tourRequests.title}
                  values={tourRequests.values}
                />
                <Description>
                  ご希望される方を対象に、現地にてスタッフがご案内をいたします。
                  <br />
                  日程の詳細はフォームをご確認ください。
                  <br />
                  <br />
                  ※建物は未竣工ですが、スタッフが計画地・現地周辺についてご案内します。
                  <br />
                  ※感染症対策のため、少人数でのご案内を予定しています。
                  <br />
                  ※申込み状況に応じて調整をさせていただくため、ご案内が出来ない場合やご希望に添い兼ねる可能性がございます。
                  <br />
                  ※往復の交通費はご負担いただきますようお願いします。
                  <br />
                </Description>
                {isNeedTourRequest() && (
                  <>
                    {tourPlans.options.map(({ title, required }, idx) => (
                      <SelectOptions
                        key={`tourPlans${idx + 1}`}
                        id="tourPlans"
                        name={`tourPlans${idx + 1}`}
                        required={required}
                        placeholder={title}
                        values={tourPlans.values}
                      />
                    ))}
                  </>
                )}
              </Section>
              <Section>
                <Button
                  type="submit"
                  disabled={loading}
                  className={loading ? 'loading' : ''}
                >
                  <span>送信</span>
                  <ButtonLoadingWrapper className={loading ? 'loading' : ''}>
                    <ButtonLoadingBar className={loading ? 'loading' : ''} />
                  </ButtonLoadingWrapper>
                </Button>
                <SubmitErrorMessageText>
                  {!!formSendingError && formSendingError}
                  {errors &&
                    (errors['finalTaxReturnFiles'] ||
                      errors['financialStatementFiles'] ||
                      errors['companyRegistrationFiles'] ||
                      errors['identificationFiles'] ||
                      errors['shareholderListFiles']) &&
                    '添付ファイルが正しくアップロードされているかご確認ください。'}
                </SubmitErrorMessageText>
              </Section>
            </>
          )}
        </Form>
      </FormProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`

const Top = styled.div`
  max-width: 760px;
  padding: 0 30px;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 1000px) {
    padding: 0 5%;
    width: 90%;
  }
`

const Form = styled.form`
  max-width: 760px;
  padding: 0 30px;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 1000px) {
    padding: 0 5%;
    width: 90%;
  }
`

const Section = styled.div`
  width: 100%;
  padding-bottom: 20px;
`

const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
`

const Label = styled.label`
  color: #737373;
  font-size: 12px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  text-align: left;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
`

const FixedLabel = styled(Label)`
  padding: 8px 20px 0;
`

const FloatingLabel = styled(Label)`
  opacity: 0;
  padding: 4px 20px 0;

  &.show {
    opacity: 1;
    padding: 8px 20px 0;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 26px 20px 10px;
  font-weight: 600;
  color: #000;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
    border-color: #007aff;
    -webkit-box-shadow: 0 0 0 1px #007aff;
    box-shadow: 0 0 0 1px #007aff;
  }

  &.message-error {
    outline: none;
    border-color: #e22120;
    -webkit-box-shadow: 0 0 0 1px #e22120;
    box-shadow: 0 0 0 1px #e22120;
  }
`

const Option = styled.option`
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
`

const Input = styled.input`
  width: 100%;
  font-weight: 600;
  padding: 18px 19px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border: 1px transparent solid;
  box-sizing: border-box;
  display: block;
  background-clip: padding-box;
  border-radius: 5px;
  word-break: normal;
  line-height: inherit;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &::placeholder {
    color: #ababab;
    font-weight: 600;
  }

  &:focus {
    outline: none;
    border-color: #007aff;
    -webkit-box-shadow: 0 0 0 1px #007aff;
    box-shadow: 0 0 0 1px #007aff;
  }

  &.show {
    padding: 24px 20px 12px;
  }

  &.message-error {
    outline: none;
    border-color: #e22120;
    -webkit-box-shadow: 0 0 0 1px #e22120;
    box-shadow: 0 0 0 1px #e22120;
  }
`

const HalfTextWrapper = styled.div`
  display: flex;
  width: 100%;

  div:first-child {
    padding-right: 8px;
  }
  div:last-child {
    padding-right: 8px;
  }
`

const RadioCheckboxContainer = styled.div`
  padding-top: 8px;
`

const RadioCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
`

const RadioCheckboxInput = styled.input`
  margin: 0.5rem;
  position: absolute;
  opacity: 0;

  + .label {
    &:before {
      content: '';
      background: #f4f4f4;
      border-radius: 100%;
      border: 1px solid darken(#f4f4f4, 25%);
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: -0.2em;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
  }
  &:checked {
    + .label {
      &:before {
        background-color: #007aff;
        box-shadow: inset 0 0 0 4px #f4f4f4;
      }
    }
  }
  &:focus {
    + .label {
      &:before {
        box-shadow: inset 0 0 0 3px #f4f4f4, 0 0 0 1px #007aff;
      }
    }
  }
  &:disabled {
    + .label {
      &:before {
        box-shadow: inset 0 0 0 4px #f4f4f4;
        border-color: darken(#f4f4f4, 25%);
        background: darken(#f4f4f4, 25%);
      }
    }
  }
  + .label {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
`

const Title = styled.h1`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  height: auto;
  font-weight: 600;
  line-height: 1.5em;
  color: #000;
  margin: 0;
`

const Title2 = styled.h2`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  font-size: 1.3em;
  line-height: 1.3em;
  color: #333333;
`

const Title3 = styled.h3`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  font-size: 1.1em;
  line-height: 1.1em;
  color: #333333;
`

const Description = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  font-size: 11px;
  line-height: 1.8;
  letter-spacing: 0.024em;
`

const ErrorMessageText = styled.p`
  line-height: 1.3em;
  margin: 0.2em 0 0.2em;
  font-size: 11px;
  letter-spacing: 0.024em;
  color: #e22120;
`

const SubmitErrorMessageText = styled.p`
  padding-top: 4px;
  line-height: 1.3em;
  margin: 0.2em 0 0.2em;
  font-size: 11px;
  letter-spacing: 0.024em;
  color: #e22120;
`

const Button = styled.button`
  width: 100%;
  cursor: pointer;
  display: inline-block;
  background-color: #007aff;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  padding: 1em;
  text-align: center;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.loading span {
    opacity: 0;
  }
`

const ButtonLoadingWrapper = styled.div`
  display: none;
  width: 100%;
  max-width: 50px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.loading {
    display: block;
  }
`

const ButtonLoadingBar = styled.div`
  font-size: 2px;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 10em;
  height: 10em;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export default App
